export const createImagePreloader2 = (url: string, alt: string) => {
  if (typeof window === 'undefined') {
    return Promise.resolve(alt);
  }

  const image = new Image();
  image.src = url;

  return new Promise<string>((resolve) => {
    image.onload = () => {
      resolve(image.src);
    };
    image.onerror = () => {
      resolve(alt);
    };
  });
};
